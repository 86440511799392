import { addEvent, removeAllEvents } from "~/foundation/Events/events";
import autosize from "autosize";
import anime from "animejs";
import { STANDARDCUBICBEZIER } from "~/foundation/Constants/easings";
import { SLOW } from "~/foundation/Constants/durations";
import { format, register } from "timeago.js";
import ar from "timeago.js/esm/lang/ar";
import { isRtl } from "~/foundation/Helpers/isRtl";
import { forEach } from "~/foundation/Helpers/forEach";
import {
    deleteElement,
    prependElement,
    appendElement,
    insertElementAfter
} from "~/foundation/Dom/elementManipulation";
import { createElement } from "~/foundation/Dom/createElement";
import { Snackbar } from "~/foundation/Components/snackbar/snackbar";
import { postComment, getComments, deleteComment } from "./api-helpers";
import { toggleClass } from "~/foundation/Dom/classList";

export class Comments {
    /**
     * Internal placeholder for cached DOM-objects.
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(config) {
        const {
            domReference,
            articleId,
            onClose,
            showSnackMessage,
            snackMessage
        } = config;

        this.dom.container = domReference;
        this.articleId = articleId;
        this.onClose = onClose;
        this.showSnackMessage = showSnackMessage;
        this.snackMessage = snackMessage;
        this.comments = [];
        this.snackbarInstance = undefined;
        this.replyMessageShown = false;
        this.texts = {
            deletedText: this.dom.container.getAttribute("data-deleted-text"),
            deleteButtonLabel: this.dom.container.getAttribute(
                "data-delete-button-label"
            ),
            replyPlaceholder: this.dom.container.getAttribute(
                "data-reply-placeholder"
            ),
            inputEmpty: this.dom.container.getAttribute("data-input-empty"),
            deleteConfirmText: this.dom.container.getAttribute(
                "data-delete-confirm-text"
            ),
            replyLabel: this.dom.container.getAttribute("data-replyLabel"),
            cancelLabel: this.dom.container.getAttribute("data-cancelLabel"),
            confirmLabel: this.dom.container.getAttribute("data-confirmLabel")
        };

        this.loadMoreCommentsAmount = 10;
        this.loadedCommentsAmount = this.loadMoreCommentsAmount;
        this.currentCommentCountIndex = 0;
        this.totalComments = undefined;

        if (isRtl) {
            register("ar", ar);
        }

        this.initialize();
    }

    //Remove all events for all buttons and textareas
    kill() {
        removeAllEvents(this.dom.submitCommentButton);
        removeAllEvents(this.dom.loadMoreButton);
        const buttons = this.dom.container.querySelectorAll("button");

        forEach(buttons, button => {
            removeAllEvents(button);
        });

        // Remove all listerners from textareas
        const textareas = this.dom.container.querySelectorAll("textarea");
        forEach(textareas, textarea => {
            autosize.destroy(textarea);
        });

        // Remove all comments - will be appended on load
        const commentItems = this.dom.container.querySelectorAll(
            ".comments-dialog__item"
        );

        deleteElement(commentItems);
    }

    /**
     * Animate in comment element
     *
     * @param {*} element
     * @param shouldStagger
     * @memberof Comments
     */
    animateInComment(element, shouldStagger = false) {
        const config = {
            targets: element,
            easing: STANDARDCUBICBEZIER,
            duration: SLOW,
            translateX: isRtl ? [15, 0] : [-15, 0],
            opacity: [0, 1]
        };
        if (shouldStagger) {
            config.delay = anime.stagger(100);
        }

        const animation = anime(config);

        return animation.finished;
    }

    animateOutComment(element, shouldStagger = false) {
        const config = {
            targets: element,
            easing: STANDARDCUBICBEZIER,
            duration: SLOW,
            translateX: isRtl ? [-15, 0] : [15, 0],
            opacity: [1, 0]
        };
        if (shouldStagger) {
            config.delay = anime.stagger(100);
        }

        const animation = anime(config);

        return animation.finished;
    }

    /**
     * Create comment element
     *
     * @param {*} commentData
     * @returns comment element
     * @memberof Comments
     */
    createComment(commentData) {
        const {
            timestamp,
            id,
            author,
            email,
            comment,
            showDelete,
            isDeleted,
            isReply
        } = commentData;

        const timeAgo = format(timestamp, isRtl ? "ar" : "en_US");

        const commentTextElement = createElement("div", {
            className: "comment__text",
            html: isDeleted ? this.texts.deletedText : comment
        });

        const html = [commentTextElement];

        if (!isDeleted) {
            const replyButtonElement = createElement("button", {
                type: "button",
                className: `button-link reply reply-comment-${id} isReply}`,
                id,
                html:
                    this.texts.replyLabel === ""
                        ? "Reply"
                        : this.texts.replyLabel
            });

            const cancelButtonElement = createElement("button", {
                type: "button",
                className: `button-link cancel cancel-reply-comment-${id} isReply}`,
                id,
                html:
                    this.texts.cancelLabel === ""
                        ? "Cancel"
                        : this.texts.cancelLabel,
                style: "opacity:0"
            });

            addEvent(replyButtonElement, "click", this.showReplyInput);
            addEvent(cancelButtonElement, "click", this.hideReplyInput);

            if (showDelete) {
                const deleteButtonElement = createElement("button", {
                    type: "button",
                    className: `button-link delete-comment ${
                        isReply ? "isReply" : "isComment"
                    }`,
                    id,
                    html:
                        this.texts.deleteButtonLabel === ""
                            ? "Delete"
                            : this.texts.deleteButtonLabel
                });

                addEvent(deleteButtonElement, "click", this.removeComment);

                html.push(deleteButtonElement);
            }

            html.push(replyButtonElement);
            html.push(cancelButtonElement);

            const authorElement = createElement("span", {
                className: "comment__author",
                html: `<strong data-toggle-author><span>${author}</span><span class="visuallyhidden">${email}</span></strong><span class="post-time">${timeAgo}</span>`
            });
            html.unshift(authorElement);
            addEvent(authorElement, "click", this.toggleAuthorLabel);
        }

        return createElement("div", {
            className: isDeleted
                ? `comment-deleted comment ${isReply ? "isReply" : "isComment"}`
                : `comment comment-${id} ${isReply ? "isReply" : "isComment"}`,
            html
        });
    }

    /**
     *  Create comment element
     *
     * @param {*} commentContainerData
     * @returns comments-dialog__item element
     * @memberof Comments
     */
    createCommentContainer(commentContainerData) {
        const { id, replies, isDeleted, showDelete } = commentContainerData;
        const repliesElements = [];
        const hasReplies = replies && replies.length > 0;

        // If any replies - replylist contains all replies
        if (hasReplies) {
            forEach(replies, reply => {
                const { id, createdDate, name, email, text, isDeleted } = reply;
                const replyElement = this.createComment({
                    id,
                    author: name,
                    email: email,
                    comment: text,
                    timestamp: createdDate,
                    isDeleted,
                    showDelete,
                    isReply: true
                });

                repliesElements.push(replyElement);
            });
        }
        const replyList = createElement("div", {
            className: "comments-dialog_reply-list",
            html: repliesElements
        });

        let replyContainer;

        // If the comment is not deleted, show reply textarea
        if (!isDeleted) {
            const replyTextarea = createElement("textarea", {
                className: `reply-${id}`,
                id,
                required: true
            });

            const labelElement = createElement("label", {
                text: this.texts.replyPlaceholder
            });

            const replySubmitButton = createElement("button", {
                className: "submit-reply",
                id,
                type: "button",
                html: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.2064 7.99029L8.43812 0.999512L7.44707 2.02514L12.53 7.27514H0.896484V8.72489H12.53L7.44707 13.9749L8.43812 15.0005L15.2064 8.00975L15.197 8.00002L15.2064 7.99029Z"
                    fill="#00A3E0" />
                    </svg>`
            });

            const replyInput = createElement("div", {
                className: ` input input--grey reply-input-${id}`,
                html: [replyTextarea, labelElement, replySubmitButton],
                style: "display:none"
            });

            replyContainer = createElement("div", {
                className: `comments-dialog__reply reply-to-${id}`,
                html: [replyInput, replyList]
            });

            addEvent(replySubmitButton, "click", this.submitReply);
            autosize(replyTextarea);
        } else {
            // If deleted, check if any replies and only add them if any
            const replyContainerConfig = {
                className: "comments-dialog__reply"
            };

            if (hasReplies) {
                replyContainerConfig.html = replyList;
            }

            replyContainer = createElement("div", replyContainerConfig);
        }

        // If comment is deleted and doesnt have any replies show nothing, if it has replies show text for deleted
        if (isDeleted && !hasReplies) {
            return createElement("div", {
                html: this.createComment(commentContainerData),
                className: "comments-dialog__item-deleted",
                style: "display:none"
            });
        } else {
            return createElement("div", {
                html: [
                    this.createComment(commentContainerData),
                    replyContainer
                ],
                className: "comments-dialog__item"
            });
        }
    }

    /**
     * Toggle between name and email visibility when clicking element
     */
    toggleAuthorLabel = e => {
        toggleClass(
            e.currentTarget.querySelectorAll("[data-toggle-author] span"),
            "visuallyhidden"
        );
    };

    /**
     * Post comment and animate in
     *
     * @memberof Comments
     */
    submitComment = () => {
        if (this.dom.commentInput.value !== "") {
            this.dom.submitCommentButton.disabled = true;
            postComment({
                Text: this.dom.commentInput.value,
                ArticleId: this.articleId
            }).then(result => {
                const { success, data } = result;
                this.dom.submitCommentButton.disabled = false;
                this.dom.commentInput.style.height = "50px";
                if (success) {
                    const commentElement = this.createCommentContainer({
                        id: data.id,
                        author: data.name,
                        email: data.email,
                        comment: this.dom.commentInput.value,
                        timestamp: data.createdDate,
                        isDeleted: data.isDeleted,
                        showDelete: data.showDelete
                    });

                    this.incrementCount();

                    commentElement.style.opacity = 0;
                    prependElement(commentElement, this.dom.commentSection);
                    setTimeout(() => {
                        this.animateInComment(commentElement);
                    }, 50);
                    this.dom.commentInput.value = "";
                    getComments(this.articleId);
                } else {
                    alert("Submit error - please try again");
                }
            });
        } else {
            alert(this.texts.inputEmpty);
        }
    };

    /**
     * Post reply and animate in
     *
     * @memberof Comments
     */
    submitReply = e => {
        const id = e.target.id;
        const textarea = this.dom.container.querySelector(`.reply-${id}`);
        const parentCommentId = textarea.id;

        if (textarea.value !== "") {
            e.target.disabled = true;
            const parent = e.target.parentNode.parentNode;
            const replyContainer = parent.querySelector(
                ".comments-dialog_reply-list"
            );
            postComment({
                Text: textarea.value,
                ArticleId: this.articleId,
                ReplyToComment: parentCommentId
            }).then(result => {
                const { success, data } = result;
                e.target.disabled = false;
                textarea.style.height = "auto";
                if (success) {
                    const commentElement = this.createComment({
                        id: data.id,
                        author: data.name,
                        email: data.email,
                        comment: textarea.value,
                        timestamp: data.createdDate,
                        isDeleted: data.isDeleted,
                        showDelete: data.showDelete,
                        isReply: true
                    });

                    this.incrementCount();
                    prependElement(commentElement, replyContainer);
                    setTimeout(() => {
                        this.animateInComment(commentElement);
                    }, 50);
                    textarea.value = "";
                    this.hideReplyInput(e);
                }
            });
        } else {
            alert(this.texts.inputEmpty);
        }
    };

    /**
     * Hide reply comment input field
     *
     * @memberof Comments
     */
    hideReplyInput = e => {
        const target = e.target;
        const id = target.id;
        const inputToHide = this.dom.container.querySelector(
            `.reply-input-${id}`
        );
        this.animateOutComment(inputToHide);
        setTimeout(() => {
            inputToHide.style.display = "none";
        }, 100);
        const cancelButtonElement = this.dom.container.querySelector(
            `.cancel-reply-comment-${id}`
        );
        cancelButtonElement.style.opacity = "0";
    };

    /**
     * Show reply comment input field
     *
     * @memberof Comments
     */
    showReplyInput = e => {
        const target = e.target;
        const id = target.id;
        const cancelButtonElement = this.dom.container.querySelector(
            `.cancel-reply-comment-${id}`
        );
        cancelButtonElement.style.opacity = "1";
        const inputToShow = this.dom.container.querySelector(
            `.reply-input-${id}`
        );
        if (inputToShow.style.display !== "block") {
            inputToShow.style.display = "block";
            this.animateInComment(inputToShow);
            inputToShow.querySelector("textarea").focus();
        }
    };

    /**
     * Remove comment from dom and db
     *
     * @memberof Comments
     */
    removeComment = e => {
        const target = e.target;
        const id = target.id;
        const elementToRemove = this.dom.container.querySelector(
            `.comment-${id}`
        );

        elementToRemove.style.zIndex = 2;
        const elementToRemoveParent = elementToRemove.parentNode;

        const deleteConfirm = new Snackbar({
            element: target,
            text: this.texts.deleteConfirmText,
            autoClose: false,
            confirm: true,
            cancelLabel: this.texts.cancelLabel,
            confirmLabel: this.texts.confirmLabel,
            position: isRtl ? "left" : "right",
            onCancel: () => {
                deleteConfirm.close();
                elementToRemove.style.zIndex = 1;
            },
            onConfirm: () => {
                deleteComment(id).then(({ success }) => {
                    if (success) {
                        deleteConfirm.close().finished.then(() => {
                            const isReply =
                                target.classList.contains("isReply");
                            const replyListElement =
                                elementToRemoveParent.querySelector(
                                    ".comments-dialog_reply-list"
                                );

                            const isCommentAndHasReplies =
                                !isReply &&
                                replyListElement &&
                                replyListElement.children.length > 0;

                            const isCommentWithoutReplies =
                                !isReply &&
                                replyListElement &&
                                replyListElement.children.length === 0;

                            const replyContainer =
                                elementToRemoveParent.querySelector(
                                    ".comments-dialog__reply"
                                );

                            let targets;

                            if (isReply || isCommentAndHasReplies) {
                                targets = elementToRemove;
                            } else if (isCommentWithoutReplies) {
                                targets = [elementToRemove, replyContainer];
                            }

                            this.decrementCount();

                            // animate out element - also replycontainer if doesnt have any replies
                            anime({
                                targets,
                                easing: STANDARDCUBICBEZIER,
                                duration: SLOW,
                                opacity: [1, 0],
                                complete: () => {
                                    if (isCommentWithoutReplies) {
                                        const textarea =
                                            replyContainer.querySelector(
                                                "textarea"
                                            );
                                        const replyButton =
                                            replyContainer.querySelector(
                                                "button"
                                            );
                                        autosize.destroy(textarea);
                                        removeAllEvents(replyButton);
                                    }
                                    removeAllEvents(e.target);
                                    anime.remove(targets);

                                    if (isCommentWithoutReplies) {
                                        deleteElement(replyContainer);
                                    }

                                    // Animate in new deletedComment
                                    const deletedCommentElement =
                                        this.createComment({
                                            id: "null",
                                            comment: this.texts.deletedText,
                                            isDeleted: true,
                                            showDelete: false
                                        });

                                    deletedCommentElement.style.opacity = 0;
                                    insertElementAfter(
                                        deletedCommentElement,
                                        elementToRemove
                                    );
                                    deleteElement(elementToRemove);
                                    setTimeout(() => {
                                        this.animateInComment(
                                            deletedCommentElement
                                        );
                                    }, 50);

                                    //if the deleted element is a reply animate out the deleted text and remove it from the dom
                                    if (
                                        deletedCommentElement.parentNode.classList.contains(
                                            "comments-dialog_reply-list"
                                        )
                                    ) {
                                        setTimeout(() => {
                                            this.animateOutComment(
                                                deletedCommentElement
                                            );
                                        }, 5000);
                                        setTimeout(() => {
                                            deletedCommentElement.style.display =
                                                "none";
                                        }, 6000);
                                    } else if (
                                        deletedCommentElement.parentNode.querySelectorAll(
                                            ".isReply"
                                        ).length === 0
                                    ) {
                                        //else if the deleted element is a comment and it has no replies animate out the deleted text
                                        setTimeout(() => {
                                            this.animateOutComment(
                                                deletedCommentElement
                                            );
                                        }, 5000);
                                        setTimeout(() => {
                                            //and remove the parent element from the dom
                                            deletedCommentElement.parentNode.style.display =
                                                "none";
                                        }, 6000);
                                    }
                                }
                            });
                        });

                        // recieve comments and display in the social bar
                        getComments(this.articleId);
                    } else {
                        alert("delete error");
                    }
                });
            }
        });
    };

    loadMore = () => {
        if (
            this.loadedCommentsAmount < this.totalComments ||
            this.loadedCommentsAmount + 1 === this.totalComments
        ) {
            // Disable button while getting more comments
            this.dom.loadMoreButton.disabled = true;
            this.currentCommentCountIndex =
                this.currentCommentCountIndex + this.loadMoreCommentsAmount;
            getComments(
                this.articleId,
                this.loadMoreCommentsAmount,
                this.currentCommentCountIndex
            ).then(result => {
                const { data, success } = result;
                this.loadedCommentsAmount =
                    this.currentCommentCountIndex + this.loadMoreCommentsAmount;

                if (success) {
                    this.addComments(data.results);

                    // If no more comments remove listeners and remove from DOM
                    if (this.loadedCommentsAmount >= this.totalComments) {
                        removeAllEvents(this.dom.loadMoreButton);
                        this.dom.loadMoreButton.style.display = "none";
                    } else {
                        this.dom.loadMoreButton.disabled = false;
                    }
                }
            });
        }
    };

    /**
     * Animate in/out comments dialog
     *
     * @param {boolean} [close=false]
     * @returns
     * @memberof Comments
     */
    toggleCommentsDialogAnimation(close = false) {
        anime.remove(this.dom.commentDialog);
        this.dom.commentSection.scrollTop = 0;

        if (this.totalComments !== 0) {
            anime.remove(
                this.dom.container.querySelector(
                    ".comments-dialog__comments-list"
                )
            );
        }

        if (!close) {
            this.dom.container.style.pointerEvents = "auto";
        }

        const timeline = anime
            .timeline({
                easing: STANDARDCUBICBEZIER,
                complete: () => {
                    if (!close) {
                        if (this.dom.firstReplyInput && this.showSnackMessage) {
                            this.snackbarInstance = new Snackbar({
                                element: this.dom.firstReplyInput,
                                text: this.snackMessage,
                                autoClose: true,
                                onClose: () => {
                                    this.snackbarInstance = undefined;
                                }
                            });
                            this.replyMessageShown = true;
                        }
                    } else {
                        this.dom.container.style.display = "none";
                        this.dom.container.style.pointerEvents = "auto";
                        this.onClose();
                    }
                },
                begin: () => {
                    if (this.snackbarInstance) {
                        this.snackbarInstance.close();
                    }

                    if (!close) {
                        this.dom.container.style.display = "block";
                    } else {
                        this.dom.container.style.pointerEvents = "none";
                    }
                }
            })
            .add({
                targets: this.dom.container,
                opacity: [0, 1],
                translateY: !close ? ["100%", "0%"] : ["0%", "100%"],
                duration: SLOW
            });

        if (!close && this.totalComments !== 0) {
            const targets = this.dom.commentDialogSection.children[1]
                ? [
                      this.dom.commentDialogSection.children[0].children,
                      this.dom.commentDialogSection.children[1].children
                  ]
                : this.dom.commentDialogSection.children[0].children;

            timeline.add({
                targets,
                opacity: [0, 1],
                translateY: [20, 0],
                duration: SLOW,
                delay: anime.stagger(100)
            });
        }

        return timeline;
    }

    addComments(data, animateIn = true) {
        const comments = [];

        forEach(data, comment => {
            const { name, email, createdDate, text } = comment;
            comments.push(
                this.createCommentContainer({
                    ...comment,
                    author: name,
                    email: email,
                    timestamp: createdDate,
                    comment: text
                })
            );
        });

        appendElement(comments, this.commentList);

        if (animateIn) {
            setTimeout(() => {
                this.animateInComment(comments, true);
            }, 50);
        }
    }

    incrementCount() {
        const count = parseInt(this.dom.commentsCount.textContent);
        this.dom.commentsCount.textContent = count + 1;
    }

    decrementCount() {
        const count = parseInt(this.dom.commentsCount.textContent);
        this.dom.commentsCount.textContent = count - 1;
    }

    initialize() {
        this.dom.commentInput =
            this.dom.container.querySelector(".comment-input");
        this.dom.submitCommentButton =
            this.dom.container.querySelector(".submit-comment");
        this.dom.loadMoreButton = this.dom.container.querySelector(
            ".load-more-comments"
        );
        this.dom.commentSection = this.dom.container.querySelector(
            ".comments-dialog__section"
        );
        this.commentList = this.dom.container.querySelector(
            ".comments-dialog__comments-list"
        );

        this.dom.commentsCount = document.querySelector(
            ".comment-dialog-button__count"
        );

        addEvent(this.dom.submitCommentButton, "click", this.submitComment);
        autosize(this.dom.commentInput);

        getComments(
            this.articleId,
            this.loadedCommentsAmount,
            this.currentCommentCountIndex
        ).then(result => {
            const { data, success } = result;
            this.totalComments = data.totalNumber;

            if (this.loadedCommentsAmount >= this.totalComments) {
                this.dom.loadMoreButton.style.display = "none";
                this.dom.loadMoreButton.disabled = true;
            } else if (this.loadedCommentsAmount < this.totalComments) {
                this.dom.loadMoreButton.style.display = "block";
                this.dom.loadMoreButton.disabled = false;
                addEvent(this.dom.loadMoreButton, "click", this.loadMore);
            }

            if (success) {
                const comments = data.results;
                this.totalComments = data.totalNumber;
                this.addComments(comments, false);

                setTimeout(() => {
                    this.dom.commentDialogSection =
                        this.dom.container.querySelector(
                            ".comments-dialog__section"
                        );
                    this.dom.firstReplyInput = this.dom.container.querySelector(
                        ".comments-dialog__reply textarea"
                    );

                    this.toggleCommentsDialogAnimation();
                }, 50);
            }
        });
    }
}
